<template>
  <HXContentCard
    title="Editar Departamento"
    :toBack="{ name: 'Departamentos' }"
  >
    <form @submit.prevent="saveData">
      <div class="row p-4">
        <div class="col-md-6">
          <div>
            <label for=" " class="form-label">Nombre Departamento</label>
            <input
              v-model="item.descripcion"
              required
              placeholder="Escriba el Nombre del departamento..."
              type="text"
              class="form-control"
              id=" "
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for=" " class="form-label">Sucursal</label>
            <b-form-select
              v-model="item.sucursal.id"
              required
              text-field="descripcion"
              value-field="id"
              :options="ListSucursales"
            >
              <template #first>
                <b-form-select-option
                  v-if="selected != null"
                  selected
                  :value="none"
                  disabled
                  >-- seleccionar Sucursal --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-4">
            <label for=" " class="form-label">Empresa</label>
            <b-form-select
              @change.native="getSucursalEmpresa($event.target.value)"
              :disabled="usuario.rol.nivel != 4 ? true : false"
              required
              v-model="item.empresa.id"
              value-field="id"
              text-field="nombre"
              :options="ListEmpresas"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button
          primary
          icon
          animation-type="vertical"
          type="submit"
          class="mr-1"
        >
          <i class="fas fa-save pr-1"></i>
          Actualizar Departamento
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
      </div>
    </form>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
export default {
  name: "DataTable",
  data() {
    return {
      usuario: { rol: { nivel: 0 } },
      id: "",
      selected: null,
      item: {
        empresa: {
          id: "",
        },
        departamento: {
          id: "",
        },
        sucursal: {
          id: "",
        },
      },
      ListEmpresas: [],
      ListSucursales: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.id = this.$route.params.id;
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    await this.getEmpresas();
    await this.getSucursalEmpresa(this.usuario.empresa.id);
    core.index();
    window.$("#datatable_empresa").DataTable();
    this.$isLoading(false);
  },
  methods: {
    async getSucursalEmpresa(id) {
      console.log(id);
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + id,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.ListSucursales = res;
        } else {
          this.ListSucursales = [];
        }
      } catch (error) {
        this.ListSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;

        console.log("list", this.ListEmpresas);
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Departamento/GetDepartamento/" + this.id,
        });
        this.item = res;

        console.log(" ", this.item);
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
    async saveData() {
      console.log("saving", this.item);
      try {
        this.item.EmpresaId = this.item.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        this.item.Descripcion = this.item.descripcion;
        this.item.SucursalId = this.item.sucursal.id;

        let res = await this.$store.dispatch("hl_post", {
          path: "Departamento/UpdateDepartamento/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/departamentos");
        else alert("Verificar");
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
